.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 0;
  padding-top: 20px;
  width: 100%;
}

.navbar_left {
  display: flex;
  align-items: center;
}

.navbar_left a {
  margin-left: 2rem;
  margin-right: 2rem;
  margin-bottom: 0.75rem;
  font-family: 'IBM Plex Mono', monospace;
  font-weight: 600;
  text-decoration: none;
  color: #FBF665;
}
